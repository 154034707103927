// extracted by mini-css-extract-plugin
export var collapsableButton = "ProjectWizardPanel__collapsableButton__KuMTX";
export var collapsableSectionHeader = "ProjectWizardPanel__collapsableSectionHeader__LT7q1";
export var column = "ProjectWizardPanel__column__RdG_T";
export var deleteConfirmationButtons = "ProjectWizardPanel__deleteConfirmationButtons__ivBWu";
export var deleteConfirmationDialog = "ProjectWizardPanel__deleteConfirmationDialog__vYQl7";
export var deleteConfirmationOverlay = "ProjectWizardPanel__deleteConfirmationOverlay__UH5un";
export var deleteConfirmationText = "ProjectWizardPanel__deleteConfirmationText__qOHdd";
export var deletePanelControl = "ProjectWizardPanel__deletePanelControl__EinD5";
export var deleteSectionControl = "ProjectWizardPanel__deleteSectionControl__j_Fmv";
export var panel = "ProjectWizardPanel__panel__EA71P";
export var panelFooter = "ProjectWizardPanel__panelFooter__QQCiP";
export var panelFooterIcon = "ProjectWizardPanel__panelFooterIcon__oIUum";
export var panelFooterText = "ProjectWizardPanel__panelFooterText__gIalK";
export var panelHeader = "ProjectWizardPanel__panelHeader__MqJbO";
export var panelHeaderTitle = "ProjectWizardPanel__panelHeaderTitle__iLJto";
export var panelHeaderWithBorder = "ProjectWizardPanel__panelHeaderWithBorder__fU3Au";
export var panelHeaderWithoutBorder = "ProjectWizardPanel__panelHeaderWithoutBorder__ZK4sM";
export var row = "ProjectWizardPanel__row__f_dCy";
export var section = "ProjectWizardPanel__section__wSQyv";
export var sectionClosed = "ProjectWizardPanel__sectionClosed__BXvLy";
export var sectionTitle = "ProjectWizardPanel__sectionTitle__aj4lI";
export var zebraRow = "ProjectWizardPanel__zebraRow__AGE_2";