// extracted by mini-css-extract-plugin
export var aboveCenter = "Popover__aboveCenter__dIi16";
export var aboveLeftEdge = "Popover__aboveLeftEdge__fnv70";
export var aboveRightEdge = "Popover__aboveRightEdge__QcHi8";
export var belowCenter = "Popover__belowCenter__RrwqV";
export var belowLeftEdge = "Popover__belowLeftEdge__UalwN";
export var belowRightEdge = "Popover__belowRightEdge__sqrYh";
export var body = "Popover__body__kvp6W";
export var column = "Popover__column__GEXH3";
export var container = "Popover__container__adRKa";
export var onLeft = "Popover__onLeft__NRX36";
export var onRight = "Popover__onRight__Juy3z";
export var row = "Popover__row__PQ76f";